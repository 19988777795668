<template>
	<div class="dialog">
		<div
			id="dialog-header"
			ref="dialogHeader"
			class="dialog__header is-flex is-align-items-center is-justify-content-space-between p-4"
		>
			<h2>Start Your Business!</h2>
			<button class="btn btn--close" @click="closeModal">
				<ion-icon name="close-outline"></ion-icon>
			</button>
		</div>
		<form ref="dialog-content" class="dialog__content" v-bar>
			<div>
				<div ref="dialog-inner" class="inner" style="height: 60vh">
					<div class="columns is-multiline">
						<div class="column is-6">
							<div
								class="field"
								:class="{
									'field--error': $v.name.$error,
								}"
							>
								<label for="name">Nume</label>
								<input type="text" v-model="name" id="name" />
								<p
									class="error"
									v-if="!$v.name.required && $v.name.$dirty"
								>
									Câmpul este obligatoriu
								</p>
								<p
									class="error"
									v-if="!$v.name.alpha && $v.name.$dirty"
								>
									Câmpul trebuie să conțină doar caractere
								</p>
							</div>
						</div>
						<div class="column is-6">
							<div class="field">
								<label for="age">Vârsta</label>
								<input type="text" v-model="age" id="age" />
							</div>
						</div>
						<div class="column is-6">
							<div
								class="field"
								:class="{ 'field--error': $v.email.$error }"
							>
								<label for="email">Mail</label>
								<input
									type="email"
									v-model="email"
									id="email"
								/>
								<p
									class="error"
									v-if="!$v.email.required && $v.email.$dirty"
								>
									Câmpul este obligatoriu
								</p>
								<p
									class="error"
									v-if="!$v.email.email && $v.email.$dirty"
								>
									Adresa de mail trebuie să fie validă
								</p>
							</div>
						</div>
						<div class="column is-6">
							<div
								class="field"
								:class="{ 'field--error': $v.place.$error }"
							>
								<label for="place">Localiate</label>
								<input type="text" v-model="place" id="place" />
								<p
									class="error"
									v-if="!$v.place.required && $v.place.$dirty"
								>
									Câmpul este obligatoriu
								</p>
							</div>
						</div>
						<div class="column is-6">
							<div
								class="field"
								:class="{ 'field--error': $v.phone.$error }"
							>
								<label for="phone">Telefon</label>
								<input type="text" v-model="phone" id="phone" />
								<div
									class="error"
									v-if="!$v.phone.required && $v.phone.$dirty"
								>
									Câmpul este obligatoriu
								</div>
								<p
									class="error"
									v-if="
										(!$v.phone.minLength ||
											!$v.phone.maxLength) &&
											$v.phone.$dirty
									"
								>
									Câmpul trebuie să conțină
									{{ $v.phone.$params.maxLength.max }} numere
								</p>
							</div>
						</div>
						<div class="column is-6">
							<div class="field">
								<label for="options">Sunt:</label>
								<select v-model="selectedOption" id="options">
									<option
										v-for="(option, index) in options"
										:key="index"
										:value="option"
										>{{ option }}</option
									>
								</select>
							</div>
						</div>
						<div
							class="column is-6"
							v-if="fieldType === 'Sunt tânăr și am vise'"
						>
							<div class="field">
								<label for="your-dream"
									>Care este visul tău?</label
								>
								<textarea
									rows="4"
									type="textarea"
									id="your-dream"
									v-model="yourDream"
								></textarea>
							</div>
						</div>
						<div
							class="column is-6"
							v-if="fieldType === 'Sunt tânăr și am vise'"
						>
							<div class="field">
								<label for="time-for-your-dream"
									>Cât timp poți acorda săptămânal visului
									tău?</label
								>
								<textarea
									rows="4"
									type="textarea"
									id="time-for-your-dream"
									v-model="timeForYourDream"
								></textarea>
							</div>
						</div>
						<div
							class="column is-6"
							v-if="
								fieldType ===
									'Sunt angajat și aș dori un salariu în plus'
							"
						>
							<div class="field">
								<label for="salary"
									>Ce sumă reprezintă pentru tine un salariu
									în plus?</label
								>
								<textarea
									rows="4"
									type="textarea"
									id="salary"
									v-model="extraSalary"
								></textarea>
							</div>
						</div>
						<div
							class="column is-6"
							v-if="fieldType !== 'Sunt tânăr și am vise'"
						>
							<div class="field">
								<label for="time-for-your-dream"
									>Cât timp poți acorda săptămânal acestui
									obiectiv?</label
								>
								<textarea
									rows="4"
									type="textarea"
									id="time-for-your-dream"
									v-model="timeForYourObjective"
								></textarea>
							</div>
						</div>
						<div class="column is-6">
							<div class="field">
								<label for="time-to-your-dream"
									>Când vrei să începi să lucrezi pentru
									el?</label
								>
								<textarea
									rows="4"
									type="textarea"
									id="time-to-your-dream"
									v-model="timeToWorkOnYourDream"
								></textarea>
							</div>
						</div>
						<div
							class="column is-6"
							v-if="fieldType === 'Sunt tânăr și am vise'"
						>
							<div class="field">
								<label for="time-slot"
									>În ce interval orar vrei să fii
									contactat?</label
								>
								<textarea
									rows="4"
									type="textarea"
									id="time-slot"
									v-model="timeSlotForContact"
								></textarea>
							</div>
						</div>
						<div class="column is-6">
							<div class="field">
								<label for="social-media"
									>Te descurci cu comunicarea în SOCIAL MEDIA
									(FB, skype, whatsup)?</label
								>
								<textarea
									rows="4"
									type="textarea"
									id="social-media"
									v-model="socialMedia"
								></textarea>
							</div>
						</div>
					</div>
				</div>
			</div>
		</form>
		<div
			id="dialog-footer"
			ref="dialogFooter"
			class="dialog__footer is-flex is-align-items-center is-justify-content-flex-end p-4"
		>
			<button class="btn btn--secondary mr-3" @click.prevent="closeModal">
				Închide
			</button>
			<button
				@click.prevent="submit"
				:disabled="$v.$anyError"
				class="btn btn--primary"
				type="submit"
			>
				Trimite
			</button>
		</div>
	</div>
</template>

<script>
	import axios from 'axios';
	import {
		required,
		minLength,
		maxLength,
		numeric,
		email,
	} from 'vuelidate/lib/validators';
	import { helpers } from 'vuelidate/lib/validators';
	const alpha = helpers.regex('alpha', /^([a-zA-Z]+\s)*[a-zA-Z]+$/);
	export default {
		props: ['fieldType', 'canCancel'],
		data() {
			return {
				name: '',
				age: null,
				email: '',
				place: '',
				phone: '',
				options: [
					'la școală',
					'angajat',
					'am afacerea mea',
					'pensionar',
					'șomer',
					'alt statut',
				],
				selectedOption: null,
				yourDream: '',
				timeForYourDream: '',
				timeToWorkOnYourDream: '',
				timeSlotForContact: '',
				timeForYourObjective: '',
				socialMedia: '',
				extraSalary: '',
			};
		},
		validations: {
			name: {
				required,
				alpha,
			},
			email: {
				required,
				email,
			},
			place: {
				required,
			},
			phone: {
				required,
				numeric,
				minLength: minLength(10),
				maxLength: maxLength(10),
			},
		},
		methods: {
			closeModal() {
				this.$emit('close');
				this.name = '';
				this.age = null;
				this.email = '';
				this.place = '';
				this.phone = '';
				this.selectedOption = null;
				this.yourDream = '';
				this.timeForYourDream = '';
				this.timeToWorkOnYourDream = '';
				this.timeSlotForContact = '';
				this.timeForYourObjective = '';
				this.socialMedia = '';
				this.extraSalary = '';
				this.$v.$reset();
			},
			submit() {
				this.$v.$touch();
				if (this.$v.$invalid) {
					return;
				} else {
					axios
						.post(
							`${process.env.VUE_APP_STRAPI_DATABASE_URL}/contacts`,
							{
								nume: this.name,
								varsta: parseInt(this.age),
								mail: this.email,
								localitate: this.place,
								telefon: parseInt(this.phone),
								sunt: this.selectedOption,
								care_este_visul_tau: this.yourDream,
								cat_timp_poti_acorda_saptamanal_visului_tau: this
									.timeForYourDream,
								ce_suma_reprezinta_pentru_tine_un_salariu_in_plus: this
									.extraSalary,
								cat_timp_poti_acorda_saptamanal_acestui_obiectiv: this
									.timeForYourObjective,
								cand_vrei_sa_incepi_sa_lucrezi_pentru_el: this
									.timeToWorkOnYourDream,
								in_ce_interval_orar_vrei_sa_fii_contactat: this
									.timeSlotForContact,
								te_descurci_cu_comunicarea_in_social_media_fb_skype_whatsup: this
									.socialMedia,
							}
						)
						.then((data) => {
							axios.post(
								`${process.env.VUE_APP_STRAPI_DATABASE_URL}/emailc`,
								{
									mail: data.data.mail,
								}
							);
							axios.post(
								`${process.env.VUE_APP_STRAPI_DATABASE_URL}/emailcd`,
								{ ...data }
							);
							this.$store.commit(
								'general/SET_FORM_MSG_AFTER_SUBMITED',
								{
									status: 'submited',
									msg:
										'Mulțumim pentru interes. Am notat datele tale și te vom contacta în cel mai scurt timp.',
								}
							);
							this.closeModal();
						})
						.catch(() => {
							this.$store.commit(
								'general/SET_FORM_MSG_AFTER_SUBMITED',
								{
									status: 'error',
									msg:
										'Am întâmpinat o eroare. Datele nu au fost trimise.',
								}
							);
							this.closeModal();
						});
				}
			},
		},
	};
</script>

<style scoped lang="scss">
	.dialog {
		background-color: $white;
		&__header {
			h2 {
				font-size: 1.125rem;
				font-weight: $semibold;
			}
		}
		&__content {
			.field {
				margin-bottom: 2rem;
				&--error {
					input,
					textarea,
					option {
						border-color: $red;
						&:focus {
							box-shadow: 0 0 0 3px rgba($red, 0.25);
						}
					}
				}
			}
			label {
				display: block;
				font-size: 1rem;
				font-weight: $medium;
				padding: 0.75rem 1rem;
				border-width: 1px 1px 0 1px;
				border-color: $gray;
				border-style: solid;
				border-top-right-radius: $round-corners;
				border-top-left-radius: $round-corners;
			}
			input,
			textarea,
			select {
				box-sizing: border-box;
				background: $white;
				border: 1px solid $gray;
				border-radius: $round-corners;
				border-top-right-radius: 0;
				border-top-left-radius: 0;
				color: $primary;
				display: block;
				font-size: 1rem;
				padding: 0.75rem 1rem;
				transition: border-color 0.3s ease-in-out,
					box-shadow 0.3s ease-in-out;
				width: 100%;
				&:focus {
					box-shadow: 0 0 0 3px rgba($primary, 0.25);
					border-color: $primary;
					outline: 0;
				}
			}
			.error {
				color: $red;
				font-size: 0.875rem;
				font-weight: $medium;
			}
		}
	}
</style>
